/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require('./bootstrap');

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

//require('./components/Example');
import ReactRenderer from './ReactRenderer';
import 'pace-js'

import JaMemberPage from './pages/ja-member-page';
import ScanApp from './components/ScanApp';

const components = [
    {
        name: "JaMemberPage",
        component: <JaMemberPage />,
    },
    {
        name: "ScanApp",
        component: <ScanApp />,
    }
]
Pace.start({
    ajax: {
        trackMethods: ['GET', 'POST', 'PUT', 'DELETE', 'REMOVE'],
    }
})
new ReactRenderer(components).renderAll()
