import React, { useState } from 'react';
import Steps from 'react-steps';
import Resizer from "react-image-file-resizer";


export default function JaMemberPage(props) {

  const resizeFile = (file) => new Promise(resolve => {
    Resizer.imageFileResizer(file, 400, 400, 'JPEG', 100, 0,
    uri => {
      resolve(uri);
    }, 'base64' );
  });

  const onChange = async (event) => {
    const file = event.target.files[0];
    const image = await resizeFile(file);
    setData({
      ...data,
      ["photo"]: image
    });    
  } 

  const json = Array();

  json[1] = [
    {
      "text": "Membership No.",
      "isActive": true,
      "isDone": false
    },
    {
      "text": "Verification",
      "isActive": false,
      "isDone": false
    },
    {
      "text": "Submit",
      "isActive": false,
      "isDone": false
    },
  ];

  json[2] = [
    {
      "text": "Membership No.",
      "isActive": true,
      "isDone": true
    },
    {
      "text": "Verification",
      "isActive": true,
      "isDone": false
    },
    {
      "text": "Submit",
      "isActive": false,
      "isDone": false
    },
  ];

  json[3] = [
    {
      "text": "Membership No.",
      "isActive": true,
      "isDone": true
    },
    {
      "text": "Verification",
      "isActive": true,
      "isDone": true
    },
    {
      "text": "Submit",
      "isActive": true,
      "isDone": false
    },
  ];

  const [count, setCount] = useState(1);
  const [error, setError] = useState(false);
  const [response, setResponse] = useState(null);
  const [data, setData] = useState({
    membership_no: "",
    otp: "",
    photo: "",
    address: "",
  });
  const handleChange = (e) => {
    const value = e.target.value;
    setData({
      ...data,
      [e.target.name]: value
    });
  };

  const handleCopyAddress = (e) => {
    setData({
      ...data,
      ["address"]: response?.postal_address
    })
  }

  const inputMemberNo = () => {
    axios
      .post('api/ja-member', {
        action: count,
        membership_no: data.membership_no
      })
      .then(response => {
        setResponse(response.data)
        setError(false)
        setCount(2)
      })
      .catch(function (error) {
        if (error.response) {

          if (error.response.status == 404) {
            setError({
              membership_no: "The membership no. is invalid."
            })
          }
          if (error.response.status == 422) {
            setError(error.response.data.errors)
          }
          //console.log(error.response.data);
          //console.log(error.response.status);
          //console.log(error.response.headers);
        } else if (error.request) {
          //console.log(error.request);
        } else {
          //console.log('Error', error.message);
        }
        //console.log(error.config);

      });
  }

  const inputOtp = () => {
    axios
      .post('api/ja-member', {
        action: count,
        membership_no: data.membership_no,
        otp: data.otp
      })
      .then(response => {
        setData({
          ...data,
          ...response.data
        });
        setResponse({
          ...data,
          ...response.data
        });
        setError(false)
        setCount(3)
      })
      .catch(function (error) {
        if (error.response.status == 404) {
          setError({
            otp: "The otp is invalid."
          })
        }
        if (error.response.status == 422) {
          setError(error.response.data.errors)
        }
      });
  }

  const inputDetails = () => {
    axios
      .post('api/ja-member', {
        action: count,
        membership_no: data.membership_no,
        otp: data.otp,
        address: data.address,
        photo: data.photo
      })
      .then(response => {
        window.location.reload(false);
        alert(response.data.message)
      })
      .catch(function (error) {        
        if (error.response.status == 422) {
          setError(error.response.data.errors)
        }
      });
  }


  const member_no =
    <div className={`card animate__animated animate__fadeIn ${(count != 1) ? 'd-none' : ''}`}>
      <div className="card-header"><strong>{props.title} </strong><small>Form</small></div>
      <div className="card-body">
        <div className="form-group">
          <label>Membership No.</label>
          <input className={`form-control ${error ? 'is-invalid' : ''}`} id="membership_no" name="membership_no" type="text" value={data.membership_no} onChange={handleChange} placeholder="Enter Membership No." />
          <div className="invalid-feedback">
            {error && error.membership_no}
          </div>
        </div>
      </div>
      <div className="card-footer">
        <button onClick={inputMemberNo} id="submit" className="btn btn-primary btn-lg btn-block" type="button"><i className="fa fa-dot-circle-o"></i> Next</button>
      </div>
    </div>

  const otp =
    <div className={`card animate__animated animate__fadeIn ${(count != 2) ? 'd-none' : ''}`}>
      <div className="card-header"><strong>{props.title} </strong><small>Form</small></div>
      <div className="card-body">
        <div className="form-group">
          <label>OTP(Sent To: {response?.mobile})</label>
          <input className={`form-control ${error ? 'is-invalid' : ''}`} id="otp" name="otp" type="text" value={data.otp} onChange={handleChange} placeholder="Enter OTP No." />
          <div className="invalid-feedback">
            {error && error.otp}
          </div>
        </div>
      </div>
      <div className="card-footer">
        <button onClick={inputOtp} id="submit" className="btn btn-primary btn-lg btn-block" type="button"><i className="fa fa-dot-circle-o"></i> Next</button>
      </div>
    </div>

  const details =
    <div className={`card animate__animated animate__fadeIn ${(count != 3) ? 'd-none' : ''}`}>
      <div className="card-header"><strong>{props.title} </strong><small>Form</small></div>
      <div className="card-body">
        <center>
          {response?.photo && <img className='rounded img-fluid' src={data?.photo} />}
          <br />
          Name: {response?.name}<br />
          Company: {response?.company}<br />
          Contact: {response?.mobile}<br />
          Address: {response?.postal_address}<br />
        </center>
        <div className="form-group">
          <label>Your Photo</label>
          <input type="file" className={`form-control ${error?.photo ? 'is-invalid' : ''}`} name="photo" accept="image/png, image/jpeg" onChange={onChange} />
          <div className="invalid-feedback">
            {error && error.photo}
          </div>
        </div>
        <div className="form-group">
          <label>Postal Address</label>
          <textarea className={`form-control ${error?.address ? 'is-invalid' : ''}`}  id="address" name="address" onChange={handleChange} type="textarea" placeholder="Enter Postal Address." value={data?.address}>
            
          </textarea>
          <div className="invalid-feedback">
            {error && error.address}
          </div>
          <span onClick={handleCopyAddress} className="btn btn-outline-primary btn-block">Same as above</span>
        </div>
      </div>
      <div className="card-footer">
        <button onClick={inputDetails} id="submit" className="btn btn-primary btn-lg btn-block" type="button"><i className="fa fa-dot-circle-o"></i> Submit</button>
      </div>
    </div>
    
  return (
    <form className=''>
      {member_no}
      {details}
      {otp}
      <Steps items={json[count]} type={'point'} />
    </form>
  )
}